import React from 'react';
import ReactDOM from 'react-dom/client';

import {initGA, initGTM} from './googleTag';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {initSentry} from './sentry';
import './table.css';
const importBuildType = async () => {
  if (process.env.REACT_APP_TYPE === 'cargo_king') {
    return import('./cargoKing/indexCargoKing');
  }

  if (process.env.REACT_APP_TYPE === 'kcharles') {
    return import('./kcharles/indexKcharles');
  }

  return Promise.reject(
    new Error('No such build target: ' + process.env.REACT_APP_BUILD_TARGET),
  );
};

const root = ReactDOM.createRoot(document.getElementById('root')!);

const liveHostnames = ['app.kcharles.com', 'app.cargoking.com'];

if (liveHostnames.includes(window.location.hostname)) {
  initGTM();
  initGA();
}

initSentry();

importBuildType()
  .then(({default: Environment}) => {
    root.render(<Environment />);
  })
  .catch((err: unknown) => {
    console.error(err);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
