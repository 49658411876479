import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

const gtmId = 'GTM-55J5BXJV';
const gaId = 'G-XE68XGYHTV';

export const initGTM = () => {
  TagManager.initialize({gtmId});
};

export const initGA = () => {
  ReactGA.initialize(gaId);
};
